import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Loader from '../shared/Loader';

class PrivateRoute extends Component {
  componentDidUpdate(prevProps, prevState) {
    const { cateringRoute, adminRoute, location,
            vendorRoute, auth, history } = this.props;

    if (!auth.loggedIn) {
      window.location.href = window.location.origin + '/login';
    }

    if (auth.userData) {
      const cateringUser = auth.userData.catering_user;
      const vendorUser = !cateringUser && !auth.userData.admin;

      if (location.pathname !== '/welcome' &&
          auth.userData.needs_user_type) {
        history.push('/welcome');
        return;
      }

      if (vendorRoute && cateringUser) {
        history.push('/catering/dashboard');
        return;
      }

      // Check that this vendor owns this truck
      if (vendorRoute && vendorUser) {
        const match = this.props.computedMatch || this.props.match;
        const currentTruck = match.params.id;
        const vendorTrucks = auth.userData.trucks.map((truck) => truck.slug);

        if (currentTruck && !vendorTrucks.includes(currentTruck)) {
          history.push('/vendor');
          return;
        }
      }

      if (cateringRoute && vendorUser) {
        history.push('/vendor');
        return;
      }

      if (adminRoute && (vendorUser || cateringUser)) {
        history.push('/error');
        return;
      }
    }
  }

  render() {
    const { loggedIn, initialized } = this.props.auth;
    const { cateringRoute, adminRoute, vendorRoute } = this.props;

    if (!initialized) {
      return null;
    }

    let content = null;

    if (loggedIn) {
      content = React.createElement(this.props.component, this.props);
    } else {
      content = <Loader />;
    }

    return (
      <Route>
        {content}
      </Route>
    );
  }
}

PrivateRoute.propTypes = {
  location: PropTypes.object,
  auth: PropTypes.object,
  history: PropTypes.object,
  cateringRoute: PropTypes.bool,
  adminRoute: PropTypes.bool,
  vendorRoute: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
